import React, { useState } from 'react'
import { useForm } from 'react-hook-form'

import { Grid, CssBaseline, Container, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { Heading } from '../../components/Text'
import { FormInput } from '../../components/FormInput'
import { GradientButton } from '../../components/Button/GradientButton'
import { NavigateBack } from '../../components/Nav/NavigateBack'

import { post } from '../../services/request'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    padding: 0,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  headerBlock: {
    paddingBottom: 5,
    marginBottom: '40px',
  },
  container: {
    display: 'flex',
    padding: '0 60px 80px 60px',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    backgroundColor: '#fff',
    borderRadius: '15px',

    [theme.breakpoints.down('xs')]: {
      padding: '12px',
      paddingBottom: '50px',
    },
  },
  input: {
    marginBottom: '30px',
  },
  button: {
    height: '50px',
    marginTop: theme.spacing(2),
  },
  login: {
    padding: 0,
    backgroundColor: '#fff',
    borderRadius: '15px',
  },
}))

export const ForgotPassword = () => {
  const classes = useStyles()
  const { i18n, t } = useTranslation('forgotPassword')
  const [apiError, setApiError] = useState(null)

  const {
    watch,
    formState: { errors },
    register,
    clearErrors,
    handleSubmit,
  } = useForm()

  const [isMailSent, setIsMailSent] = useState(false)

  const onSubmit = async (values: any) => {
    const { newPasswordDestination: email } = values
    try {
      setApiError(null)
      const response = await post('/auth/forgotPassword', {
        email: email && email.trim(),
        language: i18n.language,
      })
      console.debug('Password change instructions were sent', { response })
      setIsMailSent(true)
    } catch (err: any) {
      if (err.message === 'email_does_not_exist') setApiError(err)
    }
  }

  const {
    ref: newPasswordDestinationInputRef,
    ...newPasswordDestinationInputProps
  } = register('newPasswordDestination', {
    required: t<string>('missingField'),
  })

  return (
    <Container className={classes.login} component="main" maxWidth={false}>
      <CssBaseline />
      <div className={classes.headerBlock}>
        <NavigateBack />
        <Heading>{t<string>('header')}</Heading>
      </div>
      <Grid className={classes.container}>
        <Container maxWidth="xs" className={classes.form}>
          {isMailSent === false ? (
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormInput
                inputName="newPasswordDestination"
                error={!!errors.newPasswordDestination}
                value={watch('newPasswordDestination')}
                label={t<string>('passwordBoxLabel')}
                clearError={() => {
                  clearErrors(['newPasswordDestination'])
                }}
                placeholder={t<string>('passwordBoxPlaceholder')}
                errorMessage={String(
                  errors?.newPasswordDestination?.message || ''
                )}
                className={classes.input}
                inputRef={newPasswordDestinationInputRef}
                {...newPasswordDestinationInputProps}
              />
              {apiError && (
                <Typography>{t<string>('emailDoesNotExist')}</Typography>
              )}
              <GradientButton
                type={'submit'}
                className={classes.button}
                disabled={!watch('newPasswordDestination')}
              >
                {t<string>('send')}
              </GradientButton>
            </form>
          ) : (
            <Typography align="center">{t<string>('emailSent')}</Typography>
          )}
        </Container>
      </Grid>
    </Container>
  )
}
