import React, { useState } from 'react'
import { navigate } from '@reach/router'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Grid, CssBaseline, Container, Typography } from '@material-ui/core'

import { useStoreActions } from '../../store/store.hooks'
import { Heading } from '../../components/Text'
import { FormInput } from '../../components/FormInput'
import { GradientButton } from '../../components/Button/GradientButton'
import { NavigateBack } from '../../components/Nav/NavigateBack'

import { useStyles } from './styles'

export const EmailLogin = () => {
  const { loginUser } = useStoreActions((actions) => actions.auth)
  const { t } = useTranslation('emailLogin')
  const [isLoginError, setIsLoginError] = useState(false)

  const classes = useStyles()
  const {
    watch,
    formState: { errors },
    register,
    setError,
    clearErrors,
    handleSubmit,
  } = useForm()

  const { ref: emailInputRef, ...emailInputProps } = register('email', {
    required: t<string>('emptyField'),
  })

  const { ref: passwordInputRef, ...passwordInputProps } = register(
    'password',
    {
      required: t<string>('emptyField'),
    }
  )

  const onSubmit = async (values: any) => {
    try {
      setIsLoginError(false)

      const loginData: any = {
        email: values.email && values.email.trim(),
        password: values.password,
      }
      await loginUser(loginData)
      // No need to navigate App.tsx will take care of routing
      // navigate('/')
    } catch (error: any) {
      // TODO: more specific ERROR HANDLING
      setError('email', {
        type: 'custom',
        message: t<string>('error'),
      })
      setError('password', { type: 'custom', message: t<string>('error') })
      console.error('Failed to sign in', error)
      if (error?.response?.data?.error === 'invalid_password') {
        setIsLoginError(true)
      }
    }
  }

  return (
    <Container className={classes.login} component="main" maxWidth={false}>
      <CssBaseline />
      <div className={classes.headerBlock}>
        <NavigateBack />
        <Heading>{t<string>('heading')}</Heading>
      </div>
      <Grid className={classes.loginContainer}>
        <Container maxWidth="xs" className={classes.loginForm}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormInput
              inputName="email"
              error={!!errors.email}
              value={watch('email')}
              label={t<string>('emailLabel')}
              clearError={() => {
                clearErrors(['email', 'password'])
              }}
              placeholder={t<string>('emailPlaceholder')}
              errorMessage={String(errors?.email?.message || '')}
              className={classes.loginInput}
              inputRef={emailInputRef}
              {...emailInputProps}
            />
            <Typography
              className={classes.forgotPassword}
              onClick={() => navigate('/forgot-password')}
            >
              {t<string>('forgotPassword')}
            </Typography>
            <FormInput
              type={'password'}
              inputName="password"
              error={!!errors.password}
              value={watch('password')}
              label={t<string>('passwordLabel')}
              clearError={() => {
                clearErrors(['email', 'password'])
              }}
              inputRef={passwordInputRef}
              placeholder={t<string>('passwordPlaceholder')}
              className={classes.loginInput}
              errorMessage={String(errors?.password?.message || '')}
              {...passwordInputProps}
            />
            {isLoginError && <Typography>{t<string>('loginError')}</Typography>}
            <GradientButton type={'submit'} className={classes.loginButton}>
              {t<string>('login')}
            </GradientButton>
          </form>
        </Container>
      </Grid>
    </Container>
  )
}
